import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reservation-details',
  templateUrl: './reservation-details.page.html',
  styleUrls: ['./reservation-details.page.scss'],
})
export class ReservationDetailsPage implements OnInit {
@Input('item')item
  constructor(
     private modalController: ModalController
  ) { }

  ngOnInit() {
    console.log(this.item)
  }
  close(){
this.modalController.dismiss()
  }

}
