import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { min } from 'date-fns';
import { GlobalVariable } from '../global';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-my-giftcards',
  templateUrl: './my-giftcards.page.html',
  styleUrls: ['./my-giftcards.page.scss'],
})
export class MyGiftcardsPage implements OnInit {
mygifts=[]
  constructor(
    private server:UserApiService,
    public globals:GlobalVariable,
    private alertController:AlertController,
    private modalController:ModalController,


  ) { 
  }
ionViewWillEnter(){
  this.my_gift_cards();

}
  ngOnInit() {
  }
  my_gift_cards() {
this.globals.loader();
    let response = this.server.my_gift_cards();
    response.subscribe(data => {
      this.globals.dismiss()
      console.log(data);
        this.mygifts = data;


    }, error => {
      this.globals.dismiss()

        this.globals.presentToast("Something went wrong check your internet connection.")

    });
}
choose(gift,index){
  console.log(gift);
  this.partialOrFull(gift);
}
async partialOrFull(gift) {
  const alert = await this.alertController.create({
    subHeader: 'Partial or full ',
    message: ' <strong>Do you wanna use full giftcard or partial</strong>',
    mode:'ios',
    buttons: [
    
      {
        text: 'Partial',
        
        cssClass: 'secondary',
        handler: () => {
          console.log('partial');
          this.partailUse(gift)
        }
      }, {
        text: 'Full',
        handler: () => {
          this.modalController.dismiss({amount:gift.amount,id:gift.giftcard_id});
         

          console.log('Confirm full');
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel: blah');
        }
      },
    ]
  });

  await alert.present();
}


close(){
  this.modalController.dismiss()
}

async partailUse(gift){
  console.log(gift)
  const prompt = await this.alertController.create({
    // header: 'Coupon',
    message: 'Enter Amount use wanna use',
    mode: 'ios',
    backdropDismiss: false,
    inputs: [
      {
        name: 'amount',
        type: 'number',
        value: Number(gift.amount),
        max:Number(gift.amount),
        min:0,
        placeholder: 'Enter amount',
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        handler: (data) => {
          console.log('Cancel clicked');
        },
      },
      {
        text: 'Apply',
        handler: (data) => {
          console.log(data.amount);
          if(Number(data.amount) > Number(gift.amount)){
            this.globals.presentToast('Cannot redeem more than gifts amount','middle',);
          }
          else{
            this.modalController.dismiss({amount:data.amount,id:gift.giftcard_id});

          }
         
        },
      },
    ],
  });
  prompt.present();
}

}
