import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-reward-notification',
  templateUrl: './reward-notification.page.html',
  styleUrls: ['./reward-notification.page.scss'],
})
export class RewardNotificationPage implements OnInit {

  constructor(public modalController: ModalController,
    public globals:GlobalVariable,
    ) { }

  ngOnInit() {
  }
close(){
  this.modalController.dismiss();
}
}
