import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-user-points',
  templateUrl: './user-points.page.html',
  styleUrls: ['./user-points.page.scss'],
})
export class UserPointsPage implements OnInit {
  @Input()data:any;
  title: any;
  image: any;
  name: any;
  price: any;
  tagline: any;
  punch_count: any;
  punch_limit: any;
  terms:any;

  constructor(
    private modalCtrl:ModalController,

  ) { 
    // console.log(this.data)
  }
  ionViewWillEnter(){
    console.log(this.data)
   this.image= this.data.image
   this.title=this.data.title;
   this.terms=this.data.terms;
   this.tagline=this.data.tagline;
   this.name=this.data.name;
   this.punch_count=this.data?.punch_count;
   this.punch_limit=this.data?.punch_limit;


  }

  ngOnInit() {
  }
close(){
  this.modalCtrl.dismiss()
}
}
